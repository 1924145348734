<template>
  <SubNav
    class="subnav-position"
    :selected="'tenants'"
    :buildingId="buildingId"
  />
  <main>
    <div v-if="tenants && tenants.length > 0" class="subnav-detail">
      <div class="d-flex flex-row-reverse">
        <router-link
          class="btn btn-primary"
          :to="{ name: 'CreateTenant', params: { buildingId: buildingId } }"
        >
          Create Tenant</router-link
        >
      </div>
      <div v-for="tenant in tenants" :key="tenant.id">
        <router-link
          :to="{
            name: 'EditTenant',
            params: { buildingId: buildingId, tenantId: tenant.id }
          }"
        >
          <div class="row single">
            <div class="col">
              {{ tenant.companyName }}
            </div>
            <div class="col">
              {{ tenant.contactName }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div v-else class="subnav-detail">
      <div class="d-flex flex-row-reverse">
        <router-link
          class="btn btn-primary"
          :to="{ name: 'CreateTenant', params: { buildingId: buildingId } }"
        >
          Create Tenant</router-link
        >
      </div>
    </div>
  </main>
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import getCollection from "@/composables/getCollection";
import getUser from "@/composables/getUser";

export default {
  props: ["buildingId"],
  components: { SubNav },
  setup(props) {
    const { user } = getUser();

    const { error: errorTenant, documents: tenants } = getCollection(
      "tenants",
      [
        ["managerId", "==", user.value.uid],
        ["buildingId", "==", props.buildingId]
      ],
      [["companyName", "asc"]]
    );

    return { errorTenant, tenants };
  }
};
</script>

<style>
</style>